<template>
  <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist."></a-result>
</template>

<script>
export default {
  name: "Error-404"
}
</script>

<style scoped>

</style>
